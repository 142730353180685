import React, { useEffect, useState } from 'react';
import './Map.css';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFetchData } from '../../../../hooks/useFetchData ';
import config from '../../../../config';

interface MapProps {
  isActive: boolean;
  callBuoy: (id:string, name: string) => void;
  setBouysLoadMap: any;
}

const Map: React.FC<MapProps> = ({ isActive, callBuoy, setBouysLoadMap }) => {

  const { apiUrl, apiKey } = config;
 

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
  });
  const { data, error, loading } = useFetchData(apiUrl);
  const [markers, setMarkers] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (isLoaded && data && !loading) {
      const markersArray = data.map((buoy: any, index: number) => (
        <Marker
          key={index}
          position={{ lat: buoy.lat, lng: buoy.longi }}
          title={buoy.name_buoy}
          onClick={() => {
            console.log('Marker clicked with id:', buoy.id_buoy);
            callBuoy(buoy.id_buoy + "", buoy.name_buoy);
          }}
          icon={{
            url: 'assets/img/icon-pointer.png',
            scaledSize: new window.google.maps.Size(46, 51),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
          }}
        />
      ));
      setBouysLoadMap(true);
      setMarkers(markersArray);
    }
  }, [isLoaded, data, loading]);

  if (!isLoaded || loading) {
    return <div className='loader'></div>;
  }

  if (error) {
    return <div>Error loading map: {error.message}</div>;
  }

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 40.6725,
    lng: -70.2188,
  };

  const mapOptions = {
    mapTypeId: 'satellite', // Tipo de mapa: 'satellite' para vista de satélite, 'terrain' para vista de terreno
  };

  return (
    <div className={`map  ${isActive ? 'map-close' : ''}`}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        options={mapOptions}
      >
        {markers}
      </GoogleMap>
    </div>
  );
};

export default Map;

